import Role from "./Role";
import Franquia from "./Franquia";
import { $constants } from "../plugins/constants";

export default class User {
  constructor(user) {
    Object.assign(this, user);
    this.color = this.getColor();
    this.descricaoSituacao = this.getDescricaoSituacao();
    this.dataBloqueio = this.getDataBloqueio();
    this.roles = (this.roles || []).map((role) => new Role(role));
    if (this.franquia) this.franquia = new Franquia(this.franquia);
    this.roleName = this.roles.length > 0 ? this.roles[0].name : "";
  }

  hasPermission(permissionBeingTested) {
    if (!this.permissions) return false;
    return this.permissions.indexOf(permissionBeingTested) !== -1;
  }

  can(permission) {
    return this.permissions.includes(permission);
  }

  get role() {
    if (!this.roles || this.roles.length === 0) return null;
    return this.roles[0];
  }

  getDescricaoSituacao() {
    if (!this.situacao) return "ATIVO";
    return this.situacao;
  }

  getDataBloqueio() {
    if (!this.data_bloqueio) return "ATIVO";
    return this.data_bloqueio;
  }

  getColor() {
    let tipo_cor = "default";

    $constants.situacoesUsuarios.forEach((i) => {
      if (i.descricao === this.situacao) {
        return (tipo_cor = i.cor);
      }
      return tipo_cor;
    });
    return tipo_cor;
  }
}
