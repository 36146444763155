<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'historico.index', params: { aluno_id: aluno_id } })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Histórico do Aluno
        </h1>
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col class="py-0" cols="8">
          <e-label>Aluno</e-label>
          <v-text-field v-model="selectedStudent" label="Aluno" dense solo disabled />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Identificação única(INEP)</e-label>
          <v-text-field v-model="inep" label="INEP" type="number" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Escola</e-label>
          <v-text-field v-model="escola" label="Escola" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Município</e-label>
          <v-text-field v-model="municipio" label="Município" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Estado</e-label>
          <v-text-field v-model="estado" label="Estado" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Ano de conclusão</e-label>
          <v-text-field v-model="ano" label="Ano" type="number" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Série</e-label>
          <e-autocomplete
            label="Série"
            :items="seriesList"
            v-model="serie"
            :item-value="(item) => item.id"
            :item-text="(item) => item.descricao"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Modalidade</e-label>
          <v-text-field v-model="modalidade" label="Ensino Regular / EJA" dense solo />
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Disciplinas</e-label>
          <e-autocomplete
            v-for="(disciplina, index) in disciplinas"
            label="Disciplina"
            :key="index"
            :items="disciplinasList"
            v-model="disciplinas[index]"
            :item-value="(item) => item.id"
            :item-text="(item) => item.descricao"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="3">
          <e-label>Médias</e-label>
          <v-text-field
            v-for="(nota, index) in medias"
            :key="index"
            v-model="medias[index]"
            label="Média"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="3">
          <e-label>CH</e-label>
          <v-text-field
            v-for="(cargahoraria, index) in carga_horaria"
            :key="index"
            v-model="carga_horaria[index]"
            label="CH"
            dense
            solo
          />
        </v-col>
        <v-col class="py-0" cols="2">
          <e-label>&nbsp;</e-label>
          <v-btn
            v-for="(nota, index) in medias"
            :key="index"
            @click.prevent="removeField(index)"
            class="mb-7"
            color="error"
            type="submit"
            block
          >
            Excluir
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-7" cols="3">
          <v-btn @click.prevent="addField" color="primary" type="submit" block
            >Adicionar disciplina</v-btn
          >
        </v-col>
        <v-col class="py-0" cols="4">
          <e-label>Resultado</e-label>
          <e-autocomplete
            label="Resultado"
            :items="resultadoList"
            v-model="resultado"
            :item-value="(item) => item.id"
            :item-text="(item) => item.descricao"
            dense
            solo
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12">
          <v-btn @click.prevent="submitForm" color="primary" type="submit" block>Salvar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </main-template>
</template>

<script>
import { $toast } from "vuetify-snackbar-toast";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.loadData();
    await this.getFranquias();
  },
  data() {
    return {
      id: "",
      aluno_id: "",
      selectedStudent: "",
      escola: "",
      municipio: "",
      estado: "",
      inep: "",
      ano: "",
      serie: "",
      modalidade: "",
      disciplinas: [],
      disciplinasList: [],
      seriesList: [],
      resultadoList: this.$constants.situacaoHistorico,
      resultado: "",
      medias: [],
      carga_horaria: [],
      fraquiaAtual: "",
    };
  },
  methods: {
    async submitForm() {
      // if (!this.formIsValid()) {
      //   $toast.danger("Preencha a diciplina junto à nota final");
      //   this.$loaderService.close();
      //   return;
      // }
      if (!this.formIsValidInputs()) {
        this.$loaderService.close();
        return;
      }

      // if (
      //   this.disciplinas[this.disciplinas.length - 1] === " " &&
      //   this.medias[this.medias.length - 1] === " " &&
      //   this.carga_horaria[this.carga_horaria.length - 1] === " "
      // ) {
      //   this.disciplinas.pop();
      //   this.medias.pop();
      //   this.carga_horaria.pop();
      // }
      const historico = {
        id: this.id,
        aluno_id: this.aluno_id,
        escola: this.escola,
        municipio: this.municipio,
        estado: this.estado,
        inep: this.inep,
        ano: this.ano,
        serie: this.serie,
        resultado: this.resultado,
        modalidade: this.modalidade,
        disciplinas: this.disciplinas,
        medias: this.medias,
        carga_horaria: this.carga_horaria,
      };
      try {
        this.$loaderService.open("Salvando dados");
        if (this.editing) {
          await this.$services.historicoService.atualizarHistorico(historico);
        } else {
          await this.$services.historicoService.criarHistorico(historico);
        }
        this.$router.push({ name: "historico.index", params: { aluno_id: this.aluno_id } });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    addField() {
      if (
        this.disciplinas[this.disciplinas.length - 1] !== " " &&
        this.medias[this.medias.length - 1] !== " " &&
        this.carga_horaria[this.carga_horaria.length - 1] !== " "
      ) {
        this.disciplinas.push(" ");
        this.medias.push(" ");
        this.carga_horaria.push(" ");
      } else {
        $toast.danger("É necessário preencher a anterior para cadastrar a pŕoxima");
      }
    },
    removeField(index) {
      this.disciplinas.splice(index, 1);
      this.medias.splice(index, 1);
      this.carga_horaria.splice(index, 1);
    },
    formIsValid() {
      let isValid = true;

      if (
        this.disciplinas.length === 0 ||
        this.medias.length === 0 ||
        this.carga_horaria.length === 0
      ) {
        $toast.danger("Preencha ao menos uma disciplina, média e carga horária.");
        return false;
      }

      this.disciplinas.forEach((item, index) => {
        if (!item || item.length === 0) {
          $toast.danger(`A disciplina na posição ${index + 1} não foi preenchida.`);
          isValid = false;
        }
      });

      this.medias.forEach((item, index) => {
        if (!item || item.length === 0) {
          $toast.danger(`A média na posição ${index + 1} não foi preenchida.`);
          isValid = false;
        }
      });

      this.carga_horaria.forEach((item, index) => {
        if (!item || item.length === 0) {
          $toast.danger(`A carga horária na posição ${index + 1} não foi preenchida.`);
          isValid = false;
        }
      });

      return isValid;
    },

    async getFranquias() {
      const aluno = await this.$services.historicoService.pegarAluno(
        this.$router.currentRoute.params.aluno_id
      );
      return aluno.franquia.descricao;
    },

    formIsValidInputs() {
      let isValid = true;
      let errorMessage = "";

      if (!this.selectedStudent) {
        errorMessage = "O campo Aluno é obrigatório.";
        isValid = false;
      }

      if (!this.inep) {
        errorMessage = "O campo INEP é obrigatório.";
        isValid = false;
      }

      if (!this.escola) {
        errorMessage = "O campo Escola é obrigatório.";
        isValid = false;
      }

      if (!this.municipio) {
        errorMessage = "O campo Município é obrigatório.";
        isValid = false;
      }

      if (!this.disciplinas) {
        errorMessage = "O campo disciplinas é obrigatório.";
        isValid = false;
      }

      if (!this.estado) {
        errorMessage = "O campo Estado é obrigatório.";
        isValid = false;
      }

      if (!this.ano) {
        errorMessage = "O campo Ano de conclusão é obrigatório.";
        isValid = false;
      }

      if (!this.serie) {
        errorMessage = "O campo Série é obrigatório.";
        isValid = false;
      }

      if (!this.modalidade) {
        errorMessage = "O campo Modalidade é obrigatório.";
        isValid = false;
      }

      if (!isValid) {
        $toast.danger(errorMessage);
      }

      return isValid;
    },
    async loadData() {
      this.disciplinasList = await this.$services.disciplinasService.syncAll();
      this.seriesList = await this.$services.seriesService.syncAll();
      if (this.editing) {
        const historico = await this.pegarHistorico();
        this.id = historico.id;
        this.selectedStudent = historico.aluno.nomecompleto;
        this.aluno_id = historico.aluno.id;
        this.escola = historico.escola;
        this.municipio = historico.municipio;
        this.estado = historico.estado;
        this.inep = historico.inep;
        this.ano = historico.ano;
        this.serie = historico.serie;
        this.resultado = historico.resultado;
        this.modalidade = historico.modalidade;
        this.disciplinas = historico.disciplinas;
        this.medias = historico.medias;
        this.carga_horaria = historico.carga_horaria;
      } else {
        const aluno = await this.$services.historicoService.pegarAluno(
          this.$router.currentRoute.params.aluno_id
        );
        this.selectedStudent = aluno.nomecompleto;
        this.municipio = aluno.municipio_residencia;
        this.inep = aluno.codigo_inep;
        this.estado = aluno.estadualidade;
        this.aluno_id = aluno.id;
        this.escola = await this.getFranquias();
        this.addField();
      }
    },
    async pegarHistorico() {
      try {
        const { historico_id } = this.$route.params;
        const historico = await this.$services.historicoService.pegarHistorico(historico_id);
        return historico[0][0];
      } catch (error) {
        return this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
