<template>
  <div>
    <span>
      <v-menu v-if="user && $can(['aulas.mudarSituacao'])" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip label :color="color" class="text-center" x-small v-bind="attrs" v-on="on">
            {{ descricaoSituacao }}
          </v-chip>
        </template>
        <v-list>
          <v-list-item
            v-for="situacao of $constants.situacoesAulas"
            :key="situacao.descricao"
            link
            @click="() => verficaMotivo(situacao.descricao)"
          >
            <v-list-item-title>{{ situacao.descricao }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-chip v-else label :color="color" x-small>
        {{ descricaoSituacao }}
      </v-chip>
    </span>
    <v-progress-linear
      v-show="atualizando"
      indeterminate
      striped
      color="primary"
    ></v-progress-linear>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="primary">
              <span class="text-h5 white--text">Informar motivo</span>
            </v-card-title>
            <v-col cols="12">
              <v-textarea
                filled
                autofocus
                v-model="motivo"
                name="input-7-4"
                label="Descreva o motivo da falta"
                value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                dense
                solo
              ></v-textarea>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog = false"> Cancelar </v-btn>
              <v-btn
                color="primary"
                text
                @click="() => changeSituacaoJustificacao(descricaoSituacaoJustificativa)"
              >
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    aula: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      atualizando: false,
      descricaoSituacao: this.aula.descricaoSituacao,
      color: this.aula.color,
      dialog: false,
      motivo: null,
      descricaoSituacaoJustificativa: null,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async verficaMotivo(novaSituacao) {
      this.motivo = this.aula.motivo;
      if (novaSituacao === "Aula inválida" || novaSituacao === "Aula rejeitada por falta") {
        this.descricaoSituacaoJustificativa = novaSituacao;
        this.dialog = !this.dialog;
        return;
      }
      this.changeSituacao(novaSituacao);
    },
    async changeSituacaoJustificacao(novaSituacao) {
      this.dialog = !this.dialog;
      this.aula.motivo = this.motivo;
      this.atualizando = true;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula,
          novaSituacao
        );
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
    async changeSituacao(novaSituacao) {
      this.atualizando = true;
      this.aula.motivo = null;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula,
          novaSituacao
        );
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
  },
};
</script>
