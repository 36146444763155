import MapCollection from "collections/map";
import Vue from "vue";
import etapasService from "@/Services/EtapasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  etapas: [],
  etapasByCircuito: [],
  carregandoEtapas: false,
  etapasLoaded: false,
  etapasList: [],
  etapasListByCircuito: [],
});
const mutations = {
  setEtapas(state, etapas) {
    const etapasMap = new MapCollection();
    etapas.map((etapa) => etapasMap.set(etapa.id, etapa));
    Vue.set(state, "etapas", etapasMap);
    Vue.set(state, "etapasList", Array.from(etapasMap.values()));
    Vue.set(state, "etapasLoaded", true);
    // state = {...state, etapas: seriesMap, etapasLoaded: true}
  },
  setEtapasByCircuito(state, etapasByCircuito) {
    const etapasMap = new MapCollection();
    etapasByCircuito.map((etapa) => etapasMap.set(etapa.id, etapa));
    Vue.set(state, "etapasByCircuito", etapasMap);
    Vue.set(state, "etapasListByCircuito", Array.from(etapasMap.values()));
    Vue.set(state, "etapasLoaded", true);
    // state = {...state, etapas: seriesMap, etapasLoaded: true}
  },
  setCarregandoEtapas(state, newValue) {
    state.carregandoEtapas = newValue;
  },
  adicionarEtapa(state, etapa) {
    state.etapas.set(etapa.id, etapa);
    Vue.set(state, "etapas", state.etapas);
    Vue.set(state, "etapasList", Array.from(state.etapas.values()));
  },
  atualizarEtapa(state, etapa) {
    state.etapas.set(etapa.id, etapa);
    Vue.set(state, "etapas", state.etapas);
    Vue.set(state, "etapasList", Array.from(state.etapas.values()));
  },
  deletarEtapa(state, etapaId) {
    state.etapas.delete(etapaId);
    Vue.set(state, "etapas", state.etapas);
    Vue.set(state, "etapasList", Array.from(state.etapas.values()));
  },
};
const actions = {
  async loadEtapas({ commit }, options = {}) {
    commit("setCarregandoEtapas", true);
    try {
      const etapasList = await etapasService.syncAll(options.force);
      commit("setEtapas", etapasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoEtapas", false);
  },
  async loadEtapasByCircuito({ commit }, options = {}) {
    commit("setCarregandoEtapas", true);
    try {
      const etapasListByCircuito = await etapasService.getEtapasByCircuitoNotaId(
        options.circuito_id
      );
      commit("setEtapasByCircuito", etapasListByCircuito);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoEtapas", false);
  },
};
const getters = {
  etapas: (state) => state.etapas,
  etapasByCircuito: (state) => state.etapasByCircuito,
  carregandoEtapas: (state) => state.carregandoEtapas,
  etapasLoaded: (state) => state.etapasLoaded,
  etapasList: (state) => state.etapasList,
  etapasListByCircuito: (state) => state.etapasListByCircuito,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
