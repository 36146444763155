<template>
  <v-row>
    <v-col>
      <v-card>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
        <v-card-actions>
          <v-btn color="orange lighten-2" text> Método de Avaliação </v-btn>

          <v-spacer></v-spacer>

          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="!show">
            <v-divider></v-divider>

            <v-card-text>
              As médias, no gráfico, são calculadas de acordo com a quantidade de provas realizadas.
              Exemplo: se o(a) aluno(a) realizou apenas 2(duas) provas a média será entre essas.
              <br /><br />
              As estrelas e a nomeclatura são calculadas de acordo com o resultado da mediana entre
              0(zero) e 10(dez). Exemplo: o(a) aluno(a) possui média 10 em língua portuguesa e 6 em
              matemática, a soma dessas dividas por 2(dois) gera a avaliação das informações
              citadas.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: { Bar },
  watch: {
    disciplinas(value) {
      this.chartData.labels = value;
    },
    notas(value) {
      this.chartData.datasets = [value];
    },
  },
  props: {
    disciplinas: {
      type: Array,
      default: () => [],
    },
    notas: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 50,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      chartData: {
        labels: this.disciplinas,
        datasets: [{ label: this.notas.label, data: this.notas.data }],
      },
      chartOptions: {
        responsive: true,
        backgroundColor: "#f9a52d",
        borderRadius: 2,
      },
    };
  },
};
</script>

<style></style>
