import MapCollection from "collections/map";
import Vue from "vue";
import autorizacoesService from "@/Services/AutorizacoesService";
import $handleError from "@/plugins/handleError";
import store from "@/store";

const state = () => ({
  autorizacoes: [],
  autorizacoesByCircuito: [],
  carregandoAutorizacoes: false,
  autorizacoesLoaded: false,
  autorizacoesList: [],
  autorizacoesListByCircuito: [],
});
const mutations = {
  setAutorizacoes(state, autoriazacoes) {
    const autoriazacoesMap = new MapCollection();
    autoriazacoes.map((autoriazacoes) => autoriazacoesMap.set(autoriazacoes.id, autoriazacoes));
    Vue.set(state, "autorizacoes", autoriazacoesMap);
    Vue.set(state, "autorizacoesList", Array.from(autoriazacoesMap.values()));
    Vue.set(state, "autorizacoesLoaded", true);
    // state = {...state, etapas: seriesMap, etapasLoaded: true}
  },
  setCarregandoAutorizacoes(state, newValue) {
    state.carregandoAutorizacoes = newValue;
  },
  adicionarAutorizacao(state, autorizacao) {
    state.autorizacoes.set(autorizacao.id, autorizacao);
    Vue.set(state, "autorizacoes", state.autorizacoes);
    Vue.set(state, "autorizacoesList", Array.from(state.autorizacoes.values()));
  },
  atualizarAutorizacao(state, autorizacao) {
    state.autorizacoes.set(autorizacao.id, autorizacao);
    Vue.set(state, "autorizacoes", state.autorizacoes);
    Vue.set(state, "autorizacoesList", Array.from(state.autorizacoes.values()));
  },
  deletaAutorizacao(state, autorizacaoId) {
    state.autoriazacoes.delete(autorizacaoId);
    Vue.set(state, "autoriazacoes", state.autoriazacoes);
    Vue.set(state, "autoriazacoesList", Array.from(state.autoriazacoes.values()));
  },
};
const actions = {
  async loadAutorizacoes({ commit }, options = {}) {
    commit("setCarregandoAutorizacoes", true);
    try {
      if (!options.tipo) {
        const currentUser = store.getters["Auth/user"];
        if (currentUser.roles.filter((d) => d.name === "professor").length) {
          options.tipo = "solicitante";
        } else {
          options.tipo = "aprovador";
        }
      }
      const autorizacoesList = await autorizacoesService.syncAll(options.tipo);
      commit("setAutorizacoes", autorizacoesList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoAutorizacoes", false);
  },
};
const getters = {
  autorizacoes: (state) => state.autorizacoes,
  autorizacoesByCircuito: (state) => state.autorizacoesByCircuito,
  carregandoAutorizacoes: (state) => state.carregandoAutorizacoes,
  autorizacoesLoaded: (state) => state.autorizacoesLoaded,
  autorizacoesList: (state) => state.autorizacoesList,
  autorizacoesListByCircuito: (state) => state.autorizacoesListByCircuito,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
