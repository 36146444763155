<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text>
        <div class="text-h6 font-weight-black">Deficientes</div>
        {{ qtd }}%
        <span class="font-weight-black"> </span>
      </v-card-text>

      <apexchart type="bar" height="130" :options="chartOptions" :series="series"></apexchart>

      <!-- <v-sheet color="light">
      </v-sheet> -->
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "DeficientesCard",
  mounted() {
    this.getDeficientes();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [
        {
          name: "Net Profit",
          data: [200],
        },
        {
          name: "Revenue",
          data: [99],
        },
        {
          name: "dasds",
          data: [76],
        },
        {
          name: "dasds",
          data: [76],
        },
        {
          name: "dasds",
          data: [76],
        },
        {
          name: "dasds",
          data: [76],
        },
      ],

      chartOptions: {
        chart: {
          type: "bar",
          offsetY: -30,
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            barHeight: "100%",
            endingShape: "rounded",
            dataLabels: {
              show: false,
              position: "bottom",
            },
          },
        },

        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },

        grid: {
          show: false,
        },

        tooltip: {
          x: {
            show: false,
          },
        },

        stroke: {
          show: false,
          width: 0,
          colors: ["transparent"],
        },

        yaxis: {
          show: false,
        },

        legend: {
          show: false,
        },

        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
          categories: [1, 2, 3, 4],
        },

        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    async getDeficientes() {
      const response = await this.$services.alunosService.deficientes();

      // eslint-disable-next-line
      this.qtd = response.data[1].toString().substr(0,4);

      const data = Object.entries(response.data[0]);
      const categories = [];
      const series = [];

      // eslint-disable-next-line
      data.map((d) => {
        categories.push(d[0]);
      });

      // eslint-disable-next-line
      data.map((d) => {
        series.push({ name: d[0], data: [d[1]] });
      });

      this.loading = false;
      this.series = [];
      this.series = [...series];

      this.chartOptions = {
        xaxis: {
          labels: {
            show: false,
          },
          categories: [...categories],
        },
      };
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
