import MapCollection from "collections/map";
import Vue from "vue";
import subEtapasService from "@/Services/SubEtapasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  subEtapas: [],
  carregandoSubEtapas: false,
  subEtapasLoaded: false,
  subEtapasList: [],
});
const mutations = {
  setSubEtapas(state, subEtapas) {
    const subEtapasMap = new MapCollection();
    subEtapas.map((subEtapa) => subEtapasMap.set(subEtapa.id, subEtapa));
    Vue.set(state, "subEtapas", subEtapasMap);
    Vue.set(state, "subEtapasList", Array.from(subEtapasMap.values()));
    Vue.set(state, "subEtapasLoaded", true);
    // state = {...state, etapas: seriesMap, etapasLoaded: true}
  },
  setCarregandoSubEtapas(state, newValue) {
    state.carregandoSubEtapas = newValue;
  },
  adicionarSubEtapa(state, subEtapa) {
    state.subEtapas.set(subEtapa.id, subEtapa);
    Vue.set(state, "subEtapas", state.subEtapas);
    Vue.set(state, "subEtapasList", Array.from(state.subEtapas.values()));
  },
  atualizarSubEtapa(state, subEtapa) {
    state.subEtapas.set(subEtapa.id, subEtapa);
    Vue.set(state, "subEtapas", state.subEtapas);
    Vue.set(state, "subEtapasList", Array.from(state.subEtapas.values()));
  },
  deletarSubEtapa(state, etapaId) {
    state.subEtapas.delete(etapaId);
    Vue.set(state, "subEtapas", state.subEtapas);
    Vue.set(state, "subEtapasList", Array.from(state.subEtapas.values()));
  },
};
const actions = {
  async loadSubEtapas({ commit }, options = {}) {
    commit("setCarregandoSubEtapas", true);
    try {
      const subEtapasList = await subEtapasService.syncAll(options.circuito_nota_id);
      commit("setSubEtapas", subEtapasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSubEtapas", false);
  },
  async loadSubEtapasByCircuito({ commit }, options = {}) {
    commit("setCarregandoSubEtapas", true);
    try {
      const subEtapasList = await subEtapasService.syncAll(options.circuito_nota_id);
      commit("setSubEtapas", subEtapasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSubEtapas", false);
  },
};
const getters = {
  subEtapas: (state) => state.subEtapas,
  carregandoSubEtapas: (state) => state.carregandoSubEtapas,
  subEtapasLoaded: (state) => state.subEtapasLoaded,
  subEtapasList: (state) => state.subEtapasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
