import AutorizacoesIndexPage from "@/Pages/Autorizacoes/Solicitante/index.vue";
import NewEditAutorizacaoPage from "@/Pages/Autorizacoes/Solicitante/NewEditAutorizacaoPage.vue";

export default [
  {
    path: "/autorizacoes/",
    name: "autorizacoes",
    component: AutorizacoesIndexPage,
  },
  {
    path: "/autorizacoes/create",
    name: "autorizacoes.create",
    component: NewEditAutorizacaoPage,
    props: { editing: false },
  },
  {
    path: "/autorizacoes/edit/:autorizacao_id",
    name: "autorizacoes.edit",
    component: NewEditAutorizacaoPage,
    props: { editing: true },
  },
];
