<template>
  <v-system-bar v-if="textoAlerta || editing" color="yellow lighten-5 pa-10">
    <v-card-text v-if="editing" class="text-center text-h5 pa-0 mt-10 font-weight-bold d-flex">
      <!-- <v-menu open-on-hover top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mt-5" outlined fab color="primary" small v-bind="attrs" v-on="on">
          <v-icon color="primary">mdi-format-list-bulleted-square</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in $constants.optionsInformativo" :key="index">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

      <v-text-field
        v-model="textoAlerta"
        placeholder="Insira aqui uma mensagem de alerta "
        class="ma-5"
        dense
        solo
      />

      <v-btn @click="save" class="mx-2 mt-5" fab small outlined color="green">
        <v-icon color="green"> check </v-icon>
      </v-btn>
    </v-card-text>

    <v-card-text v-else class="lineUp text-center text-h5 pt-4 font-weight-bold piscar">
      <div>
        <v-menu open-on-hover top>
          <template v-slot:activator="{ on, attrs }">
            <div class="mt-5" outlined fab color="primary" small v-bind="attrs" v-on="on">
              {{ textoAlerta }}
            </div>
          </template>

          <v-list class="text-center" v-if="bloquearEdicao === false">
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="edit()" class="mx-2 mt-0" outlined color="green">
                  <v-icon color="green"> edit </v-icon>
                  Editar Texto
                </v-btn>
                <v-btn @click="deleteAlerta" class="mx-2 mt-0" outlined color="red">
                  <v-icon color="red"> delete </v-icon>
                  Remover Alerta
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-text>
  </v-system-bar>
</template>

<script>
export default {
  props: {
    texto: {
      type: String,
    },
    novo: {
      type: Boolean,
    },
  },
  watch: {
    novo(valor) {
      if (valor === true) {
        this.editing = true;
      }
    },
  },
  data() {
    return {
      textoAlerta: "",
      editing: false,
      informativo: null,
      bloquearEdicao: true,
    };
  },
  mounted() {
    this.getRoleAtual();
    this.getInformativo();
  },
  methods: {
    async getRoleAtual() {
      const roleAtual = await this.$services.authService.getCurrentUser();
      if (roleAtual.roles[0].name === "super-admin" || roleAtual.roles[0].name === "admin") {
        this.bloquearEdicao = false;
      } else {
        this.$emit("bloquearBotaoNovo", true);
      }
    },
    async getInformativo() {
      try {
        // verificar role
        const infor = await this.$services.informativosService.syncAll();
        if (infor.length) {
          this.ocultarBotaoNovo();
          this.informativo = infor[0];
          this.textoAlerta = infor[0].texto;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    ocultarBotaoNovo() {
      this.$emit("situacaoBtnAlerta", false);
    },

    async save() {
      this.editing = false;

      try {
        await this.$services.informativosService.create({ texto: this.textoAlerta });
        this.ocultarBotaoNovo();
        this.editing = false;
      } catch (error) {
        this.$handleError(error);
      }
    },

    edit() {
      this.editing = true;
    },

    async deleteAlerta() {
      try {
        await this.$services.informativosService.delete();
        this.textoAlerta = null;
        this.novo = false;
        this.$emit("situacaoBtnAlerta", true);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
