import Axios from "@/plugins/Axios";
import Aluno from "@/Models/Aluno";
import Matricula from "@/Models/Matricula";

class AlunosService {
  async syncAll() {
    const response = await Axios().get("alunos");
    const data = response.data.map((aluno) => new Aluno(aluno));
    return data;
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(`alunos/listAllPaginate?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async pegarAluno(id) {
    const response = await Axios().get(`alunos/${id}`);
    const data = new Aluno(response.data);
    return data;
  }

  async criarAluno(aluno) {
    const response = await Axios().post("alunos", aluno);
    const novaAluno = new Aluno(response.data);
    return novaAluno;
  }

  async getAlunoComINEP(codigoInep) {
    const response = await Axios().get(`alunos/codigo-inep/${codigoInep}`);
    return response.data ? new Aluno(response.data) : null;
  }

  async atualizarAluno(aluno) {
    const response = await Axios().put(`alunos/${aluno.id}`, aluno);
    const alunoAtualizada = new Aluno(response.data);
    return alunoAtualizada;
  }

  async deletarAluno(aluno) {
    const response = await Axios().delete(`alunos/${aluno.id}`);
    const alunoDeletada = new Aluno(response.data);
    return alunoDeletada;
  }

  async recuperarAluno(aluno) {
    return this.atualizarAluno({ ...aluno, deleted_at: null });
  }

  async pesquisaAvancada(form) {
    const response = await Axios().post("alunos/pesquisa-avancada", form);
    const alunos = response.data.map((aluno) => new Aluno(aluno));
    return alunos;
  }

  async matriculasAluno(form) {
    const response = await Axios().post("alunos/matriculas-aluno", form);
    const matriculas = response.data.map((matricula) => new Matricula(matricula));
    return matriculas;
  }

  async faixaEtaria() {
    const response = await Axios().get(`alunos/faixa-etaria`);
    return response;
  }

  async zona() {
    const response = await Axios().get(`alunos/zona`);
    return response;
  }

  async generos() {
    const response = await Axios().get(`alunos/generos`);
    return response;
  }

  async deficientes() {
    const response = await Axios().get(`alunos/deficientes`);
    return response;
  }

  async uploadLaudo(laudoData, alunoId) {
    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });
    const response = await Axios().post(`alunos/upload-laudo/${alunoId}`, laudoData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  }

  async downloadLaudo(alunoId) {
    const response = await Axios()
      .get(`alunos/download-laudo/${alunoId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        window.open(a.href);
      });

    return response;
  }
}

export default new AlunosService();
