import Axios from "@/plugins/Axios";

class WebInfo {
  async pegarIpLocalDoUsuario() {
    try {
      const response = await Axios().get("https://api.ipify.org");
      // const response = await Axios().get("https://api.ipify");
      // const response = await Axios().get("https://checkip.amazonaws.com"); TESTE OUTR API - NÃO TRATADO PARA SER USADA COMO SEGUNDA OPÇÃO
      // console.log('ip');
      // console.log(response.data);
      return response.data;
    } catch (error) {
      return "sem_ip";
    }
  }

  pegarDispositivoDoUsuario() {
    /* --------------------------------------------------- PERIGO! ----------------------------------------*/
    /* ------------- NÃO TROCAR AS ORDENS --------------------*/
    try {
      const dispostivo = navigator.platform;
      // const dispositivo = navigator.userAgent;
      // console.log(dispostivo);
      // console.log(navigator.userAgent);
      let dispositivo_do_usuario = "";
      if (dispostivo.indexOf("Win") !== -1) {
        dispositivo_do_usuario = "Windows";
        return dispositivo_do_usuario;
      }
      if (dispostivo.indexOf("Mac") !== -1 || dispostivo.indexOf("MacIntel") !== -1) {
        dispositivo_do_usuario = "MacOS";
        return dispositivo_do_usuario;
      }
      if (dispostivo.indexOf("armv8l") !== -1 || dispostivo.indexOf("Android") !== -1) {
        dispositivo_do_usuario = "Android";
        return dispositivo_do_usuario;
      }
      if (dispostivo.indexOf("iPhone") !== -1 || dispostivo.indexOf("Iphone") !== -1) {
        dispositivo_do_usuario = "Iphone";
        return dispositivo_do_usuario;
      }
      if (dispostivo.indexOf("Linux") !== -1) {
        dispositivo_do_usuario = "Linux";
        return dispositivo_do_usuario;
      }
      return 0;
    } catch (error) {
      return error;
    }
  }
}
export default new WebInfo();
