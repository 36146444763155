import MapCollection from "collections/map";
import Vue from "vue";
import circuitoNotasService from "@/Services/CircuitoNotasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  circuitos: [],
  carregandoCircuitos: false,
  circuitosLoaded: false,
  circuitosList: [],
  circuitosGeral: [],
  circuitosListGeral: [],
});
const mutations = {
  setCircuitos(state, circuitos) {
    const circuitosMap = new MapCollection();
    circuitos.map((circuito) => circuitosMap.set(circuito.id, circuito));
    Vue.set(state, "circuitos", circuitosMap);
    Vue.set(state, "circuitosList", Array.from(circuitosMap.values()));
    Vue.set(state, "circuitosLoaded", true);
    // state = {...state, circuitos: seriesMap, circuitosLoaded: true}
  },
  setCircuitosGeral(state, circuitos) {
    const circuitosMap = new MapCollection();
    circuitos.map((circuito) => circuitosMap.set(circuito.id, circuito));
    Vue.set(state, "circuitosGeral", circuitosMap);
    Vue.set(state, "circuitosListGeral", Array.from(circuitosMap.values()));
    Vue.set(state, "circuitosLoaded", true);
    // state = {...state, circuitos: seriesMap, circuitosLoaded: true}
  },
  setCarregandoCircuitos(state, newValue) {
    state.carregandoCircuitos = newValue;
  },
  adicionarCircuito(state, circuito) {
    state.circuitos.set(circuito.id, circuito);
    Vue.set(state, "circuitos", state.circuitos);
    Vue.set(state, "circuitosList", Array.from(state.circuitos.values()));
  },
  atualizarCircuito(state, circuito) {
    state.circuitos.set(circuito.id, circuito);
    Vue.set(state, "circuitos", state.circuitos);
    Vue.set(state, "circuitosList", Array.from(state.circuitos.values()));
  },
  deletarCircuito(state, circuitoId) {
    state.circuitos.delete(circuitoId);
    Vue.set(state, "circuitos", state.circuitos);
    Vue.set(state, "circuitosList", Array.from(state.circuitos.values()));
  },
};
const actions = {
  async loadCircuitos({ commit }, options = {}) {
    commit("setCarregandoCircuitos", true);
    try {
      const circuitosList = await circuitoNotasService.syncAll(options.force);
      commit("setCircuitos", circuitosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoCircuitos", false);
  },
  async loadCircuitosGeral({ commit }, options = {}) {
    commit("setCarregandoCircuitos", true);
    try {
      const circuitosListGeral = await circuitoNotasService.syncAllGeral(options.force);
      commit("setCircuitosGeral", circuitosListGeral);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoCircuitos", false);
  },
  async importarCircuito({ commit }, options = {}) {
    try {
      const circuitosList = await circuitoNotasService.importarCircuito(options.circuito_id);
      commit("setCircuitos", circuitosList);
    } catch (error) {
      $handleError(error);
    }
  },
};
const getters = {
  circuitos: (state) => state.circuitos,
  circuitosGeral: (state) => state.circuitosGeral,
  carregandoCircuitos: (state) => state.carregandoCircuitos,
  circuitosLoaded: (state) => state.circuitosLoaded,
  circuitosList: (state) => state.circuitosList,
  circuitosListGeral: (state) => state.circuitosListGeral,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
