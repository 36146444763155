import Axios from "@/plugins/Axios";
import store from "@/store";
import SubEtapa from "@/Models/SubEtapa";

export class SubEtapasService {
  async syncAll(circuito_nota_id) {
    const response = await Axios().get(`sub-etapas?circuito_nota_id=${circuito_nota_id}`);
    const subEtapas = response.data.map((subEtapa) => new SubEtapa(subEtapa));
    store.commit("SubEtapas/setSubEtapas", subEtapas);
    return subEtapas;
  }

  async criarSubEtapa(subEtapa) {
    const response = await Axios().post("sub-etapas", subEtapa);
    const novoSubEtapa = response.data;
    store.commit("SubEtapas/adicionarSubEtapa", novoSubEtapa);
    return novoSubEtapa;
  }

  async atualizarSubEtapa(circuito) {
    const response = await Axios().put(`sub-etapas/${circuito.id}`, circuito);
    const etapa = response.data;
    store.commit("SubEtapas/atualizarSubEtapa", etapa);
    return etapa;
  }

  async deletarSubEtapa(dado) {
    const response = await Axios().delete(`sub-etapas/${dado.id}`);
    const subEtapaDeletada = response.data;
    store.commit("SubEtapas/deletarSubEtapa", dado.id);
    return subEtapaDeletada;
  }
}
export default new SubEtapasService();
