<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Dados do Diário
        </h1>
      </v-col>
    </v-row>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" class="pt-0 pb-0">
        <v-sheet color="white" elevation="2" width="100%" rounded="lg">
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">Professores:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">Curso:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Turma:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Turno:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Aulas Ministradas:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">CH Comprida:</h4>
              <v-progress-linear v-model="knowledge" height="25">
                <strong>{{ Math.ceil(knowledge) }}%</strong>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" class="pt-0 pb-0">
        <v-sheet color="white" elevation="2" width="100%" rounded="lg">
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">Professores:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">Curso:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Turma:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Turno:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="4">
              <h4 class="text-caption ma-0 pa-0">Aulas Ministradas:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5">
            <v-col class="ma-0 pr-5 pb-0 pt-0 pl-5" cols="12" md="12">
              <h4 class="text-caption ma-0 pa-0">CH Comprida:</h4>
              <p class="text-subtitle-2 ma-0 pa-0">Fula de Tal</p>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import GestaoDeAula from "@/Models/GestaoDeAula";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style></style>
