import authService from "@/Services/AuthService";
import store from "@/store";
import $handleError from "@/plugins/handleError";

class LoaderService {
  static instance;

  static openFunction = () => {};

  static closeFunction = () => {};

  static getInstance() {
    if (!LoaderService.instance) {
      LoaderService.instance = new LoaderService();
    }
    return LoaderService.instance;
  }

  setOpenFunction(fn) {
    LoaderService.openFunction = fn;
  }

  setCloseFunction(fn) {
    LoaderService.closeFunction = fn;
  }

  open(message) {
    if (!LoaderService.openFunction) return;
    LoaderService.openFunction(message || "");
  }

  close() {
    if (!LoaderService.openFunction) return;
    LoaderService.closeFunction();
  }

  async loadBasicDataIfNeeded() {
    if (authService.isLoggedIn()) {
      // this.open("Carregando dados");
      try {
        await authService.syncUserWithStore();
        await store.dispatch("Franquias/loadFranquias");
      } catch (e) {
        $handleError(e);
      } finally {
        // this.close();
      }
    }
  }
}

export const $loaderService = LoaderService.getInstance();

export default (vue) => {
  vue.prototype.$loaderService = $loaderService;
};
