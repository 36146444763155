<template>
  <v-chip label color="green" class="text-center" x-small>
    {{ aula.tipo_de_aula || "- - -" }}
  </v-chip>
</template>

<script>
export default {
  props: {
    aula: {
      type: Object,
      required: true,
    },
  },
};
</script>
