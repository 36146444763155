import Planos from "@/Pages/Planos/index.vue";
import PlanosInfantil from "@/Pages/PlanosInfantil/index.vue";
import PlanosVisualizar from "@/Pages/Planos/visualizar.vue";
import PlanosInfantilVisualizar from "@/Pages/PlanosInfantil/visualizar.vue";

export default [
  {
    path: "/gestoes-de-aulas/planos",
    name: "gestoesDeAulas.planos",
    component: Planos,
  },
  {
    path: "/gestoes-de-aulas/planos/:id/visualizar",
    name: "gestoesDeAulas.planosVisualizar",
    component: PlanosVisualizar,
  },
  // Infantil
  {
    path: "/gestoes-de-aulas/planos/infantil",
    name: "gestoesDeAulas.planosInfantil",
    component: PlanosInfantil,
  },
  {
    path: "/gestoes-de-aulas/planos/infantil/:id/visualizar",
    name: "gestoesDeAulas.planosInfantilVisualizar",
    component: PlanosInfantilVisualizar,
  },
];
