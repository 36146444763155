import Model from "./Model";
import { $constants } from "../plugins/constants";

export default class Plano extends Model {
  constructor(plano) {
    super();
    Object.assign(this, plano);
    this.descricaoPlano = this.getDescricaoSituacao();
    this.color = this.getColor();
  }

  getDescricaoSituacao() {
    const status = this.status?.descricao;
    if (!status) return "Aguardando confirmação";
    return status;
  }

  getColor() {
    const tipo_cor = "orange";
    const descricaoSituacao = this.getDescricaoSituacao();

    const payload = $constants.situacoesPlanos
      .filter((i) => {
        return i.descricao.toUpperCase() === descricaoSituacao.toUpperCase();
      })
      .shift();

    return payload ? payload.cor : tipo_cor;
  }
}
