<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário online - Professor auxiliar
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            @click="criarGestaoDeAula"
            class="mr-2"
            v-show="$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"
            >Criar Vínculo</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="gestoesDeAulasList"
          :loading="carregandoGestoesDeAulas"
          :items-per-page="10"
          hide-default-footer
          no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="200">
              <tr
                color="primary"
                :class="gestaoDeAulas.estaDeletado ? ['deleted'] : []"
                v-for="gestaoDeAulas of items"
                :key="gestaoDeAulas.id"
              >
                <td>{{ gestaoDeAulas.id }}</td>
                <td width="25%">
                  {{ gestaoDeAulas.matricula ? gestaoDeAulas.matricula.nomecompleto : "- - -" }}
                </td>
                <td>{{ gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -" }}</td>
                <td class="text-left">
                  <e-resumo-disciplinas
                    :items="
                      gestaoDeAulas.disciplinas_vinculo ? gestaoDeAulas.disciplinas_vinculo : []
                    "
                  />
                </td>
                <td width="25%">
                  {{ gestaoDeAulas.nomeProfessor ? gestaoDeAulas.nomeProfessor : "- - -" }}
                </td>
                <!-- <td>{{ gestaoDeAulas.contexto }}</td> -->
                <td>
                  <div class="text-center">
                    <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="indigo" dark v-bind="attrs" v-on="on"> AÇÕES </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar color="primary">
                              <v-list-item-title style="color: #fff">{{
                                gestaoDeAulas.disciplina
                                  ? gestaoDeAulas.disciplina.descricao.substring(0, 1).toUpperCase()
                                  : "- - -"
                              }}</v-list-item-title>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                gestaoDeAulas.nomeProfessor ? gestaoDeAulas.nomeProfessor : "- - -"
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >{{ gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -" }} /
                                {{
                                  gestaoDeAulas.nomeDisciplina
                                    ? gestaoDeAulas.nomeDisciplina
                                    : "- - -"
                                }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                          <!-- item -->
                          <v-list-item-group color="primary">
                            <v-list-item
                              :to="{
                                name: 'gestoesAuxiliaresRelatorio.newEdit',
                                params: {
                                  gestaoId: gestaoDeAulas.id,
                                },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-book</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Lançar relatório</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="() => modalRelatorio(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Gerar relatório</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <v-list-item-group
                            color="primary"
                            v-if="
                              !gestaoDeAulas.estaDeletado &&
                              $can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                            "
                          >
                            <v-list-item @click="() => editarGestaoDeAula(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar gestão de aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <v-list-item-group color="primary">
                            <v-list-item @click="() => deletarGestaoDeAula(gestaoDeAulas)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar gestão de aulas </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarGestoesDeAulas"
          :search="search"
        ></e-paginate>
        <!-- eslint-disable  -->
        <v-dialog v-model="dialog" persistent width="500">
          <v-card>
            <v-card-title class="text-h6 primary white--text"> Escolha um disciplina </v-card-title>
            <e-autocomplete
              class="mx-2 mt-2"
              :items="disciplinas"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="disciplina_id"
              label="Selecione uma disciplina"
            />

            <e-autocomplete
              class="mx-2"
              :items="meses"
              return-object
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="selectMonth"
              label="Selecione um mes"
            />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog = !dialog"> Fechar </v-btn>
              <v-btn color="primary" text @click="gerarRelatorio"> Gerar relatório </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import GestaoDeAula from "@/Models/GestaoDeAula";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      search: "",
      gestoesDeAulasList: [],
      pageNumber: 0,
      carregandoGestoesDeAulas: false,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Aluno", value: "nomeFranquia" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Disciplina", value: "nomeDisciplina" },
          { text: "Professor", value: "nomeProfessor" },
          { text: "", value: "actions", sortable: false, width: "200px" },
        ],
      },
      disciplinas: [],
      gestao_id: null,
      disciplina_id: null,
      dialog: false,
      meses: [],
      selectMonth: {
        id: 2,
        descricao: "Fevereiro",
      },
    };
  },
  async mounted() {
    this.load();
  },
  watch: {
    carregandoGestoesDeAulas(value) {
      if (value && this.gestoesDeAulasList.length === 0) {
        this.$loaderService.open("Carregando gestoesDeAulas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 2) {
        await this.listarGestoesDeAulas(0, val);
      }
      if (!val) {
        await this.listarGestoesDeAulas(0);
      }
    },
  },
  methods: {
    goBack() {
      if (this.user.role.name.includes("prof")) {
        // this.$router.push({ name: "acessoProfessor.home" });
        this.$router.go(-1);
      }
      this.$router.push({ name: "home" });
    },
    async listarGestoesDeAulas(pageNumber, query = null) {
      this.carregandoGestoesDeAulas = true;
      try {
        const response = await this.$services.gestoesDeAulasAuxiliaresService.syncAll(
          pageNumber,
          query
        );
        this.gestoesDeAulasList = await response.data.map((gestao) => new GestaoDeAula(gestao));
        this.paginate = response;
      } catch {
        this.carregandoGestoesDeAulas = false;
      }
      this.carregandoGestoesDeAulas = false;
    },
    criarGestaoDeAula() {
      this.$router.push({ name: "gestoesDeAulasAuxiliares.newEdit" });
    },
    async editarGestaoDeAula(gestaoDeAulas) {
      this.$router.push({
        name: "gestoesDeAulasAuxiliares.edit",
        params: { gestaoDeAulasId: gestaoDeAulas.id },
      });
    },
    async deletarGestaoDeAula(gestaoDeAulas) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasAuxiliaresService.deletarGestaoDeAula(
                  gestaoDeAulas
                );
                this.$toast.success("GestaoDeAula deletada com sucesso");
                this.$forceUpdate();
                this.load();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async modalRelatorio(gestao) {
      this.disciplinas = gestao.disciplinas_vinculo;
      this.gestao_id = gestao.id;
      this.dialog = !this.dialog;
    },
    async gerarRelatorio() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioAuxiliar(
          this.gestao_id,
          this.disciplina_id,
          this.selectMonth.id
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async load() {
      try {
        this.$loaderService.open("Carregando gestoesDeAulas");
        await this.listarGestoesDeAulas(this.pageNumber);
        this.meses = await this.$services.anosService.meses();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
