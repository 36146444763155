import Axios from "@/plugins/Axios";
import Plano from "../Models/Plano";

export class PlanosService {
  async index(pageNumber, query, turma, disciplina, bimestre) {
    const planos = await Axios().get(
      `planos/planos-bimestrais?page=${pageNumber}&query=${query}&turma=${turma}&disciplina=${disciplina}&bimestre=${bimestre}`
    );
    return planos.data;
  }

  async show(planoId) {
    const response = await Axios().get(`planos/planos-bimestrais/${planoId}/visualizar`);
    return response;
  }

  async update(planoId, statusId) {
    const response = await Axios().put(
      `planos/planos-bimestrais/${planoId}/${statusId}/atualizarSituacao`
    );
    return response.data;
  }

  async pdf(planoId) {
    const base64 = await Axios()
      .get(`planos/planos-bimestrais/${planoId}/relatorio`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));

    return `data:application/pdf;base64, ${base64}`;
  }

  async disciplinas() {
    const response = await Axios().get(`planos/planos-bimestrais/disciplinas`);
    return response;
  }

  async turmas() {
    const response = await Axios().get(`planos/planos-bimestrais/turmas`);
    return response;
  }

  async meses() {
    const response = await Axios().get(`planos/planos-bimestrais/listarMeses`);
    return response;
  }

  async bimestres() {
    const response = await Axios().get(`planos/planos-bimestrais/listarBimestres`);
    return response;
  }

  // infantil
  async turmasInfantil() {
    const response = await Axios().get(`planos/planos-bimestrais-infantil/turmas-infantil`);
    return response;
  }

  async bimestresInfantil() {
    const response = await Axios().get(
      `planos/planos-bimestrais-infantil/listar-bimestres-infantil`
    );
    return response;
  }

  async indexInfantil(pageNumber, query, turma, disciplina, bimestre) {
    const planos = await Axios().get(
      `planos/planos-bimestrais-infantil?page=${pageNumber}&query=${query}&turma=${turma}&disciplina=${disciplina}&bimestre=${bimestre}`
    );
    return planos.data;
  }

  async updateInfantil(planoId, statusId) {
    const response = await Axios().put(
      `planos/planos-bimestrais-infantil/${planoId}/${statusId}/atualizarSituacao`
    );
    return response.data;
  }

  async showInfantil(planoId) {
    const response = await Axios().get(`planos/planos-bimestrais-infantil/${planoId}/visualizar`);
    return response;
  }

  async pdfInfantil(planoId) {
    const base64 = await Axios()
      .get(`planos/planos-bimestrais-infantil/${planoId}/relatorio`, {
        responseType: "arraybuffer",
      })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));

    return `data:application/pdf;base64, ${base64}`;
  }
}

export default new PlanosService();
