import Axios from "@/plugins/Axios";

class HorariosService {
  async horariosAll() {
    const response = await Axios().get("aulas/listar-horarios");
    return response.data;
  }
}

export default new HorariosService();
