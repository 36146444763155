<template>
  <div class="text-center">
    <v-dialog v-model="showModal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> {{ dadosModal.titulo }} </v-card-title>

        <v-card-text class="pt-5">
          {{ dadosModal.mensagem1 }}
        </v-card-text>
        <v-card-text>
          {{ dadosModal.mensagem2 }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal()"> {{ dadosModal.botao }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dadosModal: {
      type: Object,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
