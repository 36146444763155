<template>
  <div>
    <v-menu bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip label :color="color" class="text-center" x-small v-bind="attrs" v-on="on">
          {{ data_bloqueio ? data_bloqueio.split("-").reverse().join("/") : "LIBERADO" }}
        </v-chip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) of ['DESBLOQUEAR', 'BLOQUEAR']"
          :key="index"
          link
          @click="() => abrirModalDate(item)"
        >
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-col class="pt-0 pb-0" cols="7">
      <v-dialog v-model="modal" persistent width="290px">
        <v-date-picker v-model="data_bloqueio" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="() => changeData()"> OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EUserDataBloq",
  data() {
    return {
      color: this.value.color,
      atualizando: false,
      modal: false,
      data_bloqueio: this.value.data_bloqueio,
    };
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    async changeData() {
      this.atualizando = true;
      try {
        const usuarioAtualizado = await this.$services.usuariosService.atualizarDataBloqueio(
          this.value.id,
          this.data_bloqueio
        );

        this.data_bloqueio = usuarioAtualizado.data_bloqueio;
        this.modal = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
    abrirModalDate(item) {
      if (item === "DESBLOQUEAR") {
        this.data_bloqueio = null;
        this.changeData();
        return;
      }
      this.modal = true;
    },
  },
};
</script>
