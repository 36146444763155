import Index from "@/Pages/GestaoDeAulasAuxiliares/index.vue";
import NewCreateGestaoDeAuxiliares from "@/Pages/GestaoDeAulasAuxiliares/NewCreateGestaoDeAuxiliares.vue";
import relatorioAuxiliares from "@/Pages/GestaoDeAulasAuxiliares/relatorioAuxiliares.vue";

export default [
  {
    path: "/gestoes-de-aulas-auxiliares",
    name: "gestoesDeAulasAuxiliares",
    component: Index,
  },
  {
    path: "/gestoes-de-aulas-auxiliares-create",
    name: "gestoesDeAulasAuxiliares.newEdit",
    component: NewCreateGestaoDeAuxiliares,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-auxiliares/edit/:gestaoDeAulasId",
    name: "gestoesDeAulasAuxiliares.edit",
    component: NewCreateGestaoDeAuxiliares,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas-auxiliares-relatorio/:gestaoId",
    name: "gestoesAuxiliaresRelatorio.newEdit",
    component: relatorioAuxiliares,
  },
];
