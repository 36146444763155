import Axios from "@/plugins/Axios";

export class EducacaoInclusivaService {
  async syncAll(form) {
    const response = await Axios().post(`educacao-inclusiva`, form);
    const payload = response.data;
    return payload;
  }

  async lancar(form) {
    await Axios().post(`educacao-inclusiva/salvar-relatorio`, form);
  }
}
export default new EducacaoInclusivaService();
