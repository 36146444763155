<template>
  <div>
    <v-chip label color="green" v-bind="{ ...$props }" x-small>
      {{ value ? value.roles[0].name : "SEM PERFIL" }}
    </v-chip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "EUserRole",
  props: {
    value: {
      type: Object,
    },
    link: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
};
</script>
