<template>
  <v-navigation-drawer width="30%" v-model="localDrawer" right temporary app>
    <v-list-item class="primary">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium white--text">Matriculas</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="aluno.sexo_id === '1' ? masculino : feminino"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ aluno.nomecompleto ? aluno.nomecompleto : "loading..." }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <!-- ESCOLA: -->
          {{
            matriculas[0]?.aluno?.franquia?.descricao
              ? matriculas[0]?.aluno?.franquia?.descricao
              : "- - -"
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list class="px-3" dense>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Matricula:</strong> {{ aluno.id ? aluno.id : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Situação:</strong> {{ aluno.situacao ? aluno.situacao : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Turma:</strong> {{ aluno.TURMA ? aluno.TURMA : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Escola: </strong>
          {{ verificarEscolas(aluno.ESCOLA) }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <v-btn @click="modal" x-small color="success">Migrar</v-btn>
        <v-tooltip v-if="!aluno.estaDeletado" color="error" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              class="ml-1"
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="() => deletarMatricula(aluno)"
            >
              <v-icon small>fa fa-trash </v-icon>
            </v-btn>
          </template>
          <span>Apagar matricula</span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-title class="mt-4">
        <v-list-item-content>
          <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item-title>
    </v-list>
    <v-list-item-title class="px-3" v-if="matriculas.length > 1">
      <p class="font-weight-bold h5">Outras matrículas relacionadas a esse aluno</p>
    </v-list-item-title>
    <v-list
      class="px-3"
      v-for="matricula in filtroIdMatriculaIgual(aluno)"
      :key="matricula.id"
      dense
    >
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Matricula:</strong> {{ matricula.id ? matricula.id : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Situação:</strong> {{ matricula.situacao ? matricula.situacao : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Turma:</strong> {{ matricula.turma ? matricula.turma?.descricao : "- - -" }}
        </p>
      </v-list-item-title>
      <v-list-item-title>
        <p class="font-weight-light subtitle-1 text-caption">
          <strong>Escola: </strong>
          {{ matricula.turma ? matricula?.turma?.franquia?.descricao : "- - -" }}
        </p>
      </v-list-item-title>

      <v-list-item-title>
        <v-btn @click="modal" x-small color="success">Migrar</v-btn>
        <v-tooltip v-if="!matricula.estaDeletado" color="error" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              class="ml-1"
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="() => deletarMatricula(matricula)"
            >
              <v-icon small>fa fa-trash </v-icon>
            </v-btn>
          </template>
          <span>Apagar matricula</span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-title class="mt-4">
        <v-list-item-content>
          <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item-title>
    </v-list>
    <e-modal-transferir-matricula
      @dialogChange="dialog = $event"
      :dialog="dialog"
      @changeDialog="modal"
      :matricula="{ ...aluno }"
    ></e-modal-transferir-matricula>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    matriculas: {
      type: Array,
      required: true,
    },
    aluno: {
      type: Object,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localMatriculas() {
      return this.matriculas;
    },
  },
  mounted() {
    this.getFranquias();
  },
  data() {
    return {
      localDrawer: this.value,
      dialog: false,
      masculino: "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png",
      feminino:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbUqkRN4QaEzCW4brgWElc7lv8HdwP8RyxJ89WdDWAoIO-rEUIACXkEXG4n2mDGMLuz4Q&usqp=CAU",
      franquias: [],
    };
  },
  methods: {
    async getFranquias() {
      this.franquias = await this.$services.franquiasService.syncAll();
    },
    filtroIdMatriculaIgual(value) {
      return this.matriculas.filter((item) => item.id !== value.id);
    },
    async modal() {
      this.dialog = !this.dialog;
    },
    verificarEscolas(escola) {
      const franquia = this.franquias.filter((dado) => dado.id === escola);
      return franquia[0]?.descricao;
    },
    async deletarMatricula(matricula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Matricula");
              try {
                await this.$services.matriculasService.deletarMatricula(matricula);
                this.$toast.success("Matricula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
