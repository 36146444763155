<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text>
        <div class="text-h6 font-weight-black">Vinculos</div>
      </v-card-text>

      <apexchart height="100" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "ProfessorVinculoCard",
  mounted() {
    this.getVinculos();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [44, 55, 200],
      chartOptions: {
        chart: {
          height: 400,
          type: "donut",
          // offsetY: -10,
          offsetX: -10,
        },

        legend: {
          show: true,
          horizontalAlign: "top",
          position: "right",
          offsetY: -5,
          // offsetX: -5,
        },

        labels: ["Efetivos", "Auxiliares", "Substitutos"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "top",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async getVinculos() {
      const response = await this.$services.professoresService.vinculos();

      this.loading = false;
      this.series = [];
      this.series.push(parseInt(response.professoresEfetivos, 10));
      this.series.push(parseInt(response.professoresAuxiliares, 10));
      this.series.push(parseInt(response.professoresSubstitutos, 10));
      // this.series = this.series.filter((s) => s);
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
