import NewEditEducacaoInclusivaPage from "@/Pages/EducacaoInclusiva/NewEditEducacaoInclusivaPage.vue";

export default [
  {
    path: "/educacao-inclusiva/create/:matricula_id",
    name: "educacaoInclusiva.create",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/edit/:matricula_id",
    name: "educacaoInclusiva.edit",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: true },
  },
];
