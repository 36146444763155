<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'alunos' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Historico acadêmico -
          <span class="text-h5">
            {{ aluno.nomecompleto ? aluno.nomecompleto : " " }}
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="adicionarHistorico" class="mr-2">Adicionar novo ano</v-btn>
          <v-btn color="success" @click="baixarHistorico" class="mr-2">Baixar Historico</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <!-- :loading="carregandoHistorico" -->
        <v-data-table
          :headers="table.headers"
          :items="historicoList"
          :items-per-page="20"
          :custom-filter="dataTableFilter"
          :search="search"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="item of items" :key="item.id" class="text-uppercase">
                <td class="text-lowercase">{{ item.id }}</td>
                <td>{{ item.serie ? item.serie : "- - -" }}</td>
                <td>{{ item.ano ? item.ano : "- - -" }}</td>
                <td>{{ item.escola ? item.escola : "- - -" }}</td>
                <td>{{ item.municipio ? item.municipio : "- - -" }}</td>
                <td>
                  <div v-if="item.flag" class="d-flex">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue-grey lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => editarHistorico(item.id)"
                        >
                          <v-icon small>fa fa-edit </v-icon>
                        </v-btn>
                      </template>
                      <span>Editar Historico</span>
                    </v-tooltip>
                    <v-tooltip color="error" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => deletarHistorico(item.id)"
                        >
                          <v-icon small>fa fa-trash </v-icon>
                        </v-btn>
                      </template>
                      <span>Apagar Historico</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script lang="js">
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      carregandoHistorico: false,
      dataTableFilter,
      search: "",
      aluno: "",
      historicoList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Serie", value: "serie" },
          { text: "Ano de Conclusão", value: "ano" },
          { text: "Escola", value: "escola" },
          { text: "Cidade", value: "municipio" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.carregarHistorico(this.$router.currentRoute.params.aluno_id);
  },
  methods: {
    async carregarHistorico(aluno_id) {
      this.carregandoHistorico = true;
      try {
        const response = await this.$services.historicoService.syncAll(aluno_id);
        this.aluno = response.historicoDoSistema[0]?.aluno;
        let lista = response.historicoExterno.map((historicoItem) => ({
          id: historicoItem.id,
          serie: historicoItem.serie,
          ano: historicoItem.ano,
          escola: historicoItem.escola,
          municipio: historicoItem.municipio,
          flag: true,
        }));
        lista.map((item) => {
          return this.historicoList.push(item);
        });
        lista = response.historicoDoSistema.map((historicoItem) => ({
          id: historicoItem.id,
          serie: historicoItem?.turma?.descricao,
          ano: historicoItem?.ano?.descricao,
          escola: historicoItem?.turma?.franquia?.descricao,
          municipio: historicoItem.turma.franquia.cidade,
          flag: false, // isso serve para indicar que todos os itens desse array não poderão ser editados nem excluidos
        }));
        lista.map((item) => {
          return this.historicoList.push(item);
        });
      } catch (error) {
        // this.$toast.danger("Matricula do aluno não encontrada!");
        this.$toast.danger(error);
      } finally {
        this.carregandoHistorico = false;
      }
    },
    adicionarHistorico() {
      this.$router.push({ name: "historico.create" });
    },
    editarHistorico(historico_id) {
      this.$router.push({ name: "historico.edit", params: { historico_id } });
    },
    async deletarHistorico(historico_id) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta item?",
        confirmationCode: historico_id,
        confirmationMessage: `Por favor, digite <strong>${historico_id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando item do historico");
              try {
                await this.$services.historicoService.deletarItem(historico_id);
                this.$toast.success("Historico deletado com sucesso");
                this.$router.go(0);
                // this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    async baixarHistorico() {
      try {
        this.$loaderService.open("Gerando Histórico...");
        await this.$services.historicoService.baixarHistorico(this.$router.currentRoute.params.aluno_id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  watch: {
    carregandoHistorico(value) {
      if (value && this.historicoList.length === 0) {
        this.$loaderService.open("Carregando histórico");
      } else {
        this.$loaderService.close();
      }
    },
  },
};
</script>

<style></style>
