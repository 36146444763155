import MapCollection from "collections/map";
import Vue from "vue";
import regrasService from "@/Services/RegrasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  regras: [],
  regrasByCircuito: [],
  carregandoEtapas: false,
  regrasLoaded: false,
  regrasList: [],
  regrasListByCircuito: [],
});
const mutations = {
  setRegras(state, etapas) {
    const etapasMap = new MapCollection();
    etapas.map((etapa) => etapasMap.set(etapa.id, etapa));
    Vue.set(state, "regras", etapasMap);
    Vue.set(state, "regrasList", Array.from(etapasMap.values()));
    Vue.set(state, "regrasLoaded", true);
    // state = {...state, etapas: seriesMap, regrasLoaded: true}
  },
  setCarregandoRegras(state, newValue) {
    state.carregandoEtapas = newValue;
  },
  adicionarRegra(state, regra) {
    state.regras.set(regra.id, regra);
    Vue.set(state, "regras", state.regras);
    Vue.set(state, "regrasList", Array.from(state.regras.values()));
  },
  atualizarRegra(state, regra) {
    state.regras.set(regra.id, regra);
    Vue.set(state, "regras", state.regras);
    Vue.set(state, "regrasList", Array.from(state.regras.values()));
  },
  deletarRegra(state, regraId) {
    state.regras.delete(regraId);
    Vue.set(state, "regras", state.regras);
    Vue.set(state, "regrasList", Array.from(state.regras.values()));
  },
};
const actions = {
  async loadRegras({ commit }, options = {}) {
    commit("setCarregandoRegras", true);
    try {
      const regrasList = await this.$services.etapasService.syncAll(options.force);
      commit("setRegras", regrasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoRegras", false);
  },
};
const getters = {
  regras: (state) => state.regras,
  regrasByCircuito: (state) => state.regrasByCircuito,
  carregandoRegras: (state) => state.carregandoRegras,
  regrasLoaded: (state) => state.regrasLoaded,
  regrasList: (state) => state.regrasList,
  regrasListByCircuito: (state) => state.regrasListByCircuito,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
